<template>
  <v-app id="app">
    <router-view />
    <Snackbar />
  </v-app>
</template>

<script>
import Snackbar from './components/Snackbar.vue';

export default {
  components: { Snackbar },
};
</script>
<style>
body {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #7a003c;
  -webkit-overflow-scrolling: touch;
}
html {
  height: 100%;
}
.v-content {
  height: 100%;
  overflow-y: scroll;
}
.flex-container-overflow {
  overflow-y: scroll;
  min-height: 70vh;
}
.v-navigation-drawer__content {
  display: flex;
  flex-direction: column;
}
</style>
