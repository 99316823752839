import { extend, setInteractionMode } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import { messages } from 'vee-validate/dist/locale/en.json';

setInteractionMode('eager');

Object.keys(rules).forEach((rule) => {
  extend(rule, {
    ...rules[rule], // copies rule configuration
    message: messages[rule] // assign message
  });
});

extend('password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target;
  },
  message: 'Passwords do not match'
});

extend('password_complexity', {
  validate(value) {
    if (value.length < 8) {
      return false;
    }
    const regex = [/[a-z]/, /[A-Z]/, /[0-9]/, /[@_!#$%^&*()<>?/|}{~:.-]/]

    let count = 0;
    for (let i = 0; i < regex.length; i += 1) {
      if (regex[i].test(value)) {
        count += 1;
      }
      if (count >= 3) {
        return true;
      }
    }
    return false;
  },
  message: 'Password should have 3 different character groups out of 4 (uppercase letter, lowercase letter, numeric, special character)'
});
