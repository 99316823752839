// eslint-disable-next-line import/named
import Vue from 'vue';
import Vuex from 'vuex';
import { storeApiPlugin } from '@/plugins/axios';
import user from './modules/user';
import snackbar from './modules/snackbar'

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    user,
    snackbar
  },
  plugins: [storeApiPlugin]
});

export default store;

store.dispatch('user/init');
