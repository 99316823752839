/* eslint-disable no-console */
import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

Vue.use(VueAxios, axios);

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 120 * 1000,
});

export function storeApiPlugin(store) {
  // Setup headers on load of plugin and page, to make request on refresh works
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = store.state.user.tokens.access;
      if (token) {
        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  const refreshAuthLogic = async (failedRequest) => {
    const refreshToken = store.state.user.tokens.refresh;
    if (!refreshToken) return Promise.reject(failedRequest);
    try {
      const response = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/auth/jwt/refresh/`, {
        refresh: refreshToken
      });
      store.commit('user/SET_TOKENS', {
        access: response.data.access,
        refresh: response.data.refresh
      });
      // eslint-disable-next-line no-param-reassign
      failedRequest.response.config.headers.Authorization = `Bearer ${response.data.access}`;
      return Promise.resolve();
    } catch (err) {
      if (err.response.status === 401) {
        store.dispatch('user/logout');
        // eslint-disable-next-line no-restricted-globals
        location.reload();
      }
      return Promise.reject(err);
    }
  }
  createAuthRefreshInterceptor(axiosInstance, refreshAuthLogic);
}

export default axiosInstance;
