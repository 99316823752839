import Vue from 'vue';
import Vuetify, { VRow } from 'vuetify/lib';
import sl from 'vuetify/es5/locale/sl';
import colors from 'vuetify/es5/util/colors';

import {
  mdiChevronLeft,
  mdiChevronRight,
  mdiMenuDown,
  mdiDelete,
  mdiPencil,
  mdiCheckboxBlankOutline,
  mdiCheckboxMarked,
  mdiMinusBoxOutline,
  mdiClose,
  mdiAccount,
  mdiMenu,
  mdiEye,
  mdiCamera,
  mdiSend, mdiPlus,
  mdiCheck,
  mdiDotsVertical, mdiAlertCircle, mdiViewDashboard, mdiTextBoxMultipleOutline, mdiChartBoxOutline,
  mdiFilter, mdiBell, mdiExitToApp, mdiViewList, mdiChartBox, mdiEmailOutline, mdiEmailOpenOutline,
  mdiFileDownload
} from '@mdi/js';

Vue.use(Vuetify, {
  components: { VRow }
});

const MY_ICONS = {
  dropdown: mdiMenuDown,
  next: mdiChevronRight,
  prev: mdiChevronLeft,
  delete: mdiDelete,
  edit: mdiPencil,
  checkboxOn: mdiCheckboxMarked,
  checkboxOff: mdiCheckboxBlankOutline,
  checkboxIndeterminate: mdiMinusBoxOutline,
  close: mdiClose,
  account: mdiAccount,
  menu: mdiMenu,
  show: mdiEye,
  camera: mdiCamera,
  send: mdiSend,
  add: mdiPlus,
  done: mdiCheck,
  more: mdiDotsVertical,
  error: mdiAlertCircle,
  dashboard: mdiViewDashboard,
  pages: mdiTextBoxMultipleOutline,
  report: mdiChartBoxOutline,
  filter: mdiFilter,
  bell: mdiBell,
  message: mdiEmailOutline,
  openedMessage: mdiEmailOpenOutline,
  downloadAttachment: mdiFileDownload,
  logout: mdiExitToApp,
  list: mdiViewList,
  bi: mdiChartBox
};

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    dark: false,
    themes: {
      light: {
        primary: '#7a003c',
        accent: colors.grey.darken3,
        secondary: '#3aa8cc',
        info: colors.blue.lighten1,
        warning: colors.amber.base,
        error: colors.deepOrange.accent4,
        success: colors.green.lighten2
      }
    },
  },
  icons: {
    iconfont: 'mdiSvg',
    values: MY_ICONS
  },
  lang: {
    locales: { sl },
    current: 'sl',
  }
});
