const state = {
  content: '',
  color: '',
  icon: ''
};

const mutations = {
  showMessage(state, payload) {
    state.content = payload.content;
    state.color = payload.color;
    state.timeout = payload.timeout;
    state.icon = payload.icon;
  }
};

export default {
  namespaced: true,
  state,
  mutations
}
