import Vue from 'vue'
import * as Sentry from '@sentry/vue';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    Vue,
    dsn: 'https://4115c7de921c497f8f2ab875df5e1550@o300035.ingest.sentry.io/5194576',
  });
  Vue.prototype.$sentry = Sentry;
}
