<template>
  <v-snackbar v-model="show" :color="color" :timeout="timeout" style="bottom: 30% !important;">
    <v-row>
      <v-col cols="12" class="d-flex text-center align-center">
        <v-icon left x-large color="white" style="position: absolute;">{{ icon }}</v-icon>
        <div class="text-center" style="width: 100%; font-size: 1.5rem !important;">
          {{ message }}
        </div>
      </v-col>
    </v-row>
  </v-snackbar>
</template>

<script>
export default {
  name: 'Snackbar',
  data() {
    return {
      show: false,
      message: 'Success',
      color: 'success',
      timeout: 4000,
      icon: '$done'
    };
  },
  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'snackbar/showMessage') {
        this.message = state.snackbar.content;
        this.color = state.snackbar.color;
        this.timeout = state.snackbar.timeout || 4000;
        this.icon = state.snackbar.icon;
        this.show = true;
      }
    });
  }
};
</script>

<style scoped></style>
