import Cookies from 'js-cookie';
import { loginWithAzure, refreshAzureToken } from "@/lib/msal";
import axiosInstance from '../../plugins/axios';

const jwtDecode = require('jwt-decode');

let refreshTask;

let notificationFetcher;

function getSavedState(key) {
  if (Cookies.get(key)) {
    return JSON.parse(Cookies.get(key));
  }
  return {}
}

function saveState(key, state) {
  Cookies.set(key, JSON.stringify(state), { expires: 7 });
}

const state = () => ({
  user: null,
  tokens: getSavedState('tokens'),
  usage: 0,
  msAccessToken: getSavedState('azureAccessToken'),
  notifications: []
});

const mutations = {
  SET_USER(state, user) {
    state.user = user || null;
  },
  SET_TOKENS(state, tokens) {
    state.tokens = tokens;
    saveState('tokens', tokens);
  },
  SET_AZURE_ACCESS_TOKEN(state, token) {
    state.msAccessToken = token;
    saveState('azureAccessToken', token);
  },
  SET_NOTIFICATIONS(state, notifications) {
    state.notifications = notifications;
  }
};

const getters = {
  isAuthenticated(state) {
    return !!state.tokens.access && !!state.tokens.refresh;
  },
  userGroups(state) {
    const { user } = state;
    if (user !== null) {
      return state.user.groups;
    }
    return ['Advanced'];
  }
};

const actions = {
  async init({ dispatch, getters }) {
    await dispatch('refreshToken');
    if (getters.isAuthenticated) {
      await dispatch('getUserData')
    }
  },
  async login({ commit, dispatch }, payload) {
    const response = await axiosInstance.post('/auth/login/', payload);
    commit('SET_TOKENS', response.data);
    dispatch('autoRefresh', response.data.access);
    dispatch('getUserData');
    return response;
  },
  async loginWithAzureTokens({ commit, dispatch }, payload) {
    const tokenLoginPayload = {
      ...payload,
      provider: "azuread-v2-tenant-oauth2"
    }
    const { data } = await axiosInstance.post('/auth/social/', tokenLoginPayload);
    commit('SET_TOKENS', data.tokens);
    dispatch('autoRefresh', data.tokens.access)
    await dispatch('getUserData')
    return data;
  },
  signUp(_, payload) {
    return axiosInstance.post('/auth/users/', payload);
  },
  logout({ commit }) {
    clearTimeout(refreshTask);
    clearInterval(notificationFetcher);
    commit('SET_TOKENS', { refresh: null, access: null });
    commit('SET_AZURE_ACCESS_TOKEN', null);
    commit('SET_NOTIFICATIONS', []);
  },
  resetPassword(_, payload) {
    return axiosInstance.post('auth/users/reset_password/', payload);
  },
  confirmResetPassword(_, payload) {
    return axiosInstance.post('auth/users/reset_password_confirm/', payload);
  },
  setPassword(_, payload) {
    return axiosInstance.post('auth/set_new_password/', payload);
  },
  async getUserData({ commit, dispatch }) {
    try {
      const { data } = await axiosInstance.get('auth/users/me/');
      commit('SET_USER', data);
      await dispatch('getUserNotifications');
      clearInterval(notificationFetcher)
      notificationFetcher = setInterval(() => dispatch('getUserNotifications'), 30 * 1000);
    } catch (e) {
      if (e.response?.status === 401) {
        dispatch('logout')
        window.location.reload();
      }
    }
  },
  async refreshToken({ commit, state, dispatch }, refreshToken = null) {
    if (!refreshToken && !state.tokens) return null;
    if (!refreshToken && !state.tokens.refresh) return null;
    const { data } = await axiosInstance.post('auth/jwt/refresh/', {
      refresh: refreshToken || state.tokens.refresh
    });
    dispatch('autoRefresh', data.access);
    commit('SET_TOKENS', data);
    return data;
  },
  autoRefresh({ dispatch }, payload) {
    let { exp } = jwtDecode(payload);
    exp *= 1000;
    const now = Date.now();
    const timeUntilRefresh = exp - now;
    clearTimeout(refreshTask);
    refreshTask = setTimeout(() => dispatch('refreshToken'), timeUntilRefresh);
  },
  async getAzureAccessToken({ commit, dispatch }) {
    const tokenResponse = await loginWithAzure();
    commit('SET_AZURE_ACCESS_TOKEN', tokenResponse.accessToken);
    await dispatch('loginWithAzureTokens', { access_token: tokenResponse.accessToken, id_token: tokenResponse.idToken })
    return tokenResponse;
  },
  async refreshAzureAccessToken({ commit, dispatch }) {
    try {
      const tokenResponse = await refreshAzureToken();
      commit('SET_AZURE_ACCESS_TOKEN', tokenResponse.accessToken);
      await dispatch('loginWithAzureTokens', { access_token: tokenResponse.accessToken, id_token: tokenResponse.idToken });
      return tokenResponse;
    } catch (e) {
      dispatch('logout')
      window.location.reload();
    }
    return null;
  },
  async getUserNotifications({ commit }) {
    const { data } = await axiosInstance.get('user-notifications/');
    commit('SET_NOTIFICATIONS', data);
  },
  async markNotificationAsRead({ dispatch }, id) {
    await axiosInstance.post(`user-notifications/${id}/mark-as-read/`);
    return dispatch('getUserNotifications');
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
