import { PublicClientApplication } from "@azure/msal-browser";

const CONFIG = {
  auth: {
    clientId: 'api://6992c08d-0f13-4e1d-91a9-ef439e83feec',
    authority: 'https://login.microsoftonline.com/3b043179-3743-4603-a834-f9b31c631235'
  }
};

const msalInstance = new PublicClientApplication(
  CONFIG
);

export const loginWithAzure = async () => {
  const request = {
    scopes: [
      "https://analysis.windows.net/powerbi/api/Workspace.ReadWrite.All",
      "https://analysis.windows.net/powerbi/api/Report.ReadWrite.All",
      "https://analysis.windows.net/powerbi/api/Dashboard.ReadWrite.All",
    ]
  };
  let tokenResponse = null;
  try {
    tokenResponse = await msalInstance.acquireTokenSilent(request);
  } catch (e) {
    console.log(e);
    tokenResponse = await msalInstance.acquireTokenPopup(request);
  }
  return tokenResponse;
};

export const refreshAzureToken = async () => {
  const accounts = await msalInstance.getAllAccounts();
  const request = {
    scopes: [
      "https://analysis.windows.net/powerbi/api/Workspace.ReadWrite.All",
      "https://analysis.windows.net/powerbi/api/Report.ReadWrite.All",
      "https://analysis.windows.net/powerbi/api/Dashboard.ReadWrite.All",
    ],
    account: accounts[0],
    forceRefresh: false
  };
  return msalInstance.acquireTokenSilent(request);
};

export default msalInstance;
