import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: '',
    redirect: "Home",
    component: () => import(/* webpackChunkName: "defaultLayout" */ '../layout/default.vue'),
    children: [
      {
        path: '/',
        name: "Home",
        component: () => import(/* webpackChunkName: "home" */ '../views/Index.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/history',
        name: 'History',
        component: () => import(/* webpackChunkName: "history" */ '../views/History.vue'),
        meta: {
          requiresAuth: true,
          allowedGroups: ["Advanced", "AdvancedBI"]
        }
      },
      {
        path: '/bi',
        name: 'BI',
        component: () => import(/* webpackChunkName: "bi" */ '../views/BI.vue'),
        meta: {
          requiresAuth: true,
          allowedGroups: ["AdvancedBI"]
        }
      },
      {
        path: '/messages',
        name: 'Messages',
        component: () => import(/* webpackChunkName: "messages" */ '../views/Messages.vue'),
        meta: {
          requiresAuth: true,
        }
      },
      {
        path: '/set-password',
        name: 'SetNewPassword',
        component: () => import(/* webpackChunkName: "messages" */ '../views/SetNewPassword.vue'),
        meta: {
          requiresAuth: true,
        }
      },
    ],
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: '',
    component: () => import(/* webpackChunkName: "unauthenticatedLayout" */ '../layout/unauthenticated.vue'),
    children: [
      {
        path: '',
        name: 'Login',
        component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
      },
    ]
  },
  {
    path: '/register',
    name: '',
    component: () => import(/* webpackChunkName: "unauthenticatedLayout" */ '../layout/unauthenticated.vue'),
    children: [
      {
        path: '',
        name: 'Register',
        component: () => import(/* webpackChunkName: "register" */ '../views/Register.vue'),
      },
    ]
  },
  {
    path: '/reset-password',
    name: '',
    component: () => import(/* webpackChunkName: "unauthenticatedLayout" */ '../layout/unauthenticated.vue'),
    children: [
      {
        path: '',
        name: 'ResetPassword',
        component: () => import(/* webpackChunkName: "reset_password" */ '../views/ResetPassword.vue'),
      },
    ]
  },
  {
    path: '/forgotten-password',
    name: '',
    component: () => import(/* webpackChunkName: "unauthenticatedLayout" */ '../layout/unauthenticated.vue'),
    children: [
      {
        path: '',
        name: 'ForgottenPassword',
        component: () => import(/* webpackChunkName: "forgotten_password" */ '../views/ForgottenPassword.vue'),
      },
    ]
  },
  {
    path: '/activation',
    name: '',
    component: () => import(/* webpackChunkName: "unauthenticatedLayout" */ '../layout/unauthenticated.vue'),
    children: [
      {
        path: '',
        name: 'Activation',
        component: () => import(/* webpackChunkName: "activation" */ '../views/Activation.vue'),
      },
    ]
  },
  {
    path: '/unsubscribe-from-auto-response',
    name: '',
    component: () => import(/* webpackChunkName: "unauthenticatedLayout" */ '../layout/unauthenticated.vue'),
    children: [
      {
        path: '',
        name: 'UnsubscribeFromAutoResponse',
        component: () => import(/* webpackChunkName: "unsubscribe_from_emails" */ '../views/UnsubscribeFromAutoResponse.vue'),
      },
    ]
  },
  {
    path: '/more-received-invoice-information',
    name: '',
    component: () => import(/* webpackChunkName: "unauthenticatedLayout" */ '../layout/unauthenticated.vue'),
    children: [
      {
        path: '',
        name: 'AddMoreInvoiceInformation',
        component: () => import(/* webpackChunkName: "add_more_info" */ '../views/AddMoreInvoiceInformation.vue'),
      },
    ]
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    if (!store.getters['user/isAuthenticated']) {
      next('/login');
    } else {
      next();
    }
  } else if (!to.name) {
    if (store.getters['user/isAuthenticated']) {
      next('/');
    } else {
      next('/login');
    }
  } else if (to.name === 'Login') {
    if (store.getters['user/isAuthenticated']) {
      next('/');
    } else {
      next();
    }
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  if (to.meta.allowedGroups !== undefined) {
    if (!to.meta.allowedGroups.some((group) => store.getters['user/userGroups'].includes(group))) {
      next('/');
      console.log('No group')
    } else {
      next();
    }
  } else {
    next()
  }
});

export default router;
