import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './registerServiceWorker';
import vuetify from './plugins/vuetify';
import './plugins';
import i18n from './plugins/i18n';

Vue.config.productionTip = false;

Vue.prototype.$snackbar = ({
  content = '', color = '', timeout = 4000, icon = ''
}) => {
  store.commit('snackbar/showMessage', {
    content, color, timeout, icon
  });
};

Vue.prototype.$msalInstance = {};

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App)
}).$mount('#app');
